import React, {Component} from 'react';
import { withTranslation } from "react-i18next";
import axios from 'axios';
import {get} from 'lodash';
import moment from 'moment';
import randomstring from "randomstring";
import { confirm } from "react-confirm-box";
import Timeline, { TodayMarker, TimelineHeaders, SidebarHeader, DateHeader, CustomHeader } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css'
import 'react-sliding-side-panel/lib/index.css';
import './../style.css';
import loading_img from './../asset/loading_iframe.gif'

class SpaceOrderCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastTap           : new Date().getTime(),                             // 记录上一次点击时间
            timeout           : '',                                               // 记录定时器
            openPanel         : false,                                            // 控制右侧面板的显示和隐藏
            pandelHeader      : 'Order Info',                                     // 面板标题
            loading           : false,                                            // 加载状态
            token             : '',                                               // token
            startTime         : moment().add(-9, "days").format('YYYY-MM-DD'),    // 开始时间
            endTime           : moment().add(9, "days").format('YYYY-MM-DD'),     // 结束时间
            openGroups        : {},                                               // 控制space的展开和收缩
            resources         : [],                                               // 资源
            newGroups         : [],                                               // 新的space
            newItems          : [],                                               // 新的order
            currentLanguage   : 'zh',                                             // 当前语言
            theme             : 'light',                                          // 当前主题
            statusFilter      : ['pending','confirmed','precheckin','checkin','checkout','timeblock'],    // order状态过滤
            isCancelStrike    : true,
            isPendingStrike   : false,
            isConfirmStrike   : false,
            isPrecheckinStrike: false,
            isCheckinStrike   : false,
            isCheckoutStrike  : false,
            isTimeblockStrike : false,
        };
    }

    componentDidMount = () => {
        // 获取外部传入的参数
        const location = window.location.href;
        // 包含token和body值
        const params = location.substring(location.lastIndexOf('/')+1);
        // 获取token
        var token = params.split('?')[0];
        // 获取语言和theme设置
        var search_params = params.split('?')[1];

        // 获取body的开始时间和结束时间，前后9天
        var startTime = moment().add(-9, "days").format('YYYY-MM-DD');
        var endTime = moment().add(9, "days").format('YYYY-MM-DD');
        if(search_params !== undefined){
            // 获取参数
            const keys = search_params.split('&').reduce((res, item) => {
                const [key, val] = item.split('=');
                res[key] = val;
                return res;
            }, {});
            // 设置开始时间和结束时间
            if(keys.startTime !== undefined){
                startTime = keys.startTime;
            }
            if(keys.endTime !== undefined){
                endTime = keys.endTime;
            }
            // 设置语言
            if(keys.l !== undefined){
                this.setState({
                    currentLanguage: keys.l
                });
                this.props.i18n.changeLanguage(keys.l);
            }
            // 设置主题
            if(keys.t !== undefined){
                this.setState({
                    theme: keys.t
                });
                document.getElementsByTagName('body')[0].className = keys.t;
            }
        }
        // 设置state参数
        this.setState({
            token    : token,
            startTime: startTime,
            endTime  : endTime
        },async ()=>{
            await this.querySpaceOrder();
            this.state.newGroups.forEach((group, index) => {
                if(group.root){
                    this.toggleGroup(group.id);
                }
            });
        })
    }

    /**
     * 加载SpaceOrder
     */
    async querySpaceOrder() {
        const options = {
            method: 'POST',
            // url: 'https://payload.exp.is/api/v1/matrix/timespans',
            url: `${process.env.REACT_APP_PAYLOAD_URL}/api/v1/matrix/timespans`,
            headers: {
                'content-type' : 'application/json',
                'Authorization': this.state.token,
            },
            data: JSON.stringify({
                "startTime": this.state.startTime,
                "endTime"  : this.state.endTime
            })
        };
        const response = await axios.request(options);
        const resources = response.data; // Response received from the API
        var _groups = [];
        var _items = [];
        resources.forEach(({name, resources}, indexs) => {
            _groups.push({
                id    : indexs + 1,
                title : name,
                root  : true,
            });
            resources.forEach((item, index) => {
                var groupId = randomstring.generate({ length: 5, charset:'alphanumeric' });
                _groups.push({
                    id        : groupId,
                    title     : item.name,
                    parent    : indexs + 1,
                    rightTitle: item.name,
                    itemProps : {
                        targetId: item.id,
                    }
                });

                item.timespans.forEach((span, index) => {
                    // 默认灰色
                    var color1 = 'rgba(250, 200, 200 , 0.6)';
                    var color2 = 'rgba(250, 200, 200, 1)';
                    var status2 = 'other';
                    if(span.purpose === 'Order'){
                        if(span.topping){
                            if(span.topping.status === 'pending'){
                                color1  = 'rgba(255, 81, 43 , 0.95)';
                                color2  = 'rgba(255, 81, 43 , 1)';
                                status2 = 'pending';
                            }else if(span.topping.status === 'confirmed'){
                                color1  = 'rgba(103, 152, 114, 0.95)';
                                color2  = 'rgba(103, 152, 114, 1)';
                                status2 = 'confirmed';
                            }else if(span.topping.status === 'preCheckedin' || span.topping.status === 'preCheckedIn'){
                                color1  = 'rgba(106, 224, 161 , 0.95)';
                                color2  = 'rgba(106, 224, 161 , 1)';
                                status2 = 'precheckin';
                            }else if(span.topping.status === 'checkedIn' || span.topping.status === 'checkedin'){
                                color1  = 'rgba(0, 122, 254 , 0.95)';
                                color2  = 'rgba(0, 122, 254 , 1)';
                                status2 = 'checkin';
                            }else if(span.topping.status === 'checkedOut' || span.topping.status === 'checkedout'){
                                color1  = 'rgba(0, 0, 0, 0.95)';
                                color2  = 'rgba(0, 0, 0, 1)';
                                status2 = 'checkout';
                            }else{
                                color1  = 'rgba(255, 215, 64, 0.8)';
                                color2  = 'rgba(255, 215, 64, 1)';
                                status2 = 'cancelled';
                            }
                        }else{
                            color1  = 'rgba(165, 30, 32, 0.5)';
                            color2  = 'rgba(165, 30, 32, 1)';
                            status2 = 'timeblock';
                        }
                    }else{
                        color1  = 'rgba(165, 30, 32, 0.5)';
                        color2  = 'rgba(165, 30, 32, 1)';
                        status2 = 'timeblock';
                    }

                    _items.push({
                        id             : randomstring.generate({ length: 5, charset:'alphanumeric' }),
                        group          : groupId,
                        title          : get(span, 'purpose') === 'Order' || get(span, 'purpose') === 'unassigned'?`${get(span, 'topping.order.customers[0].lastName', '')} ${get(span, 'topping.order.customers[0].firstName', '')}`: get(span, 'name'),
                        start_time     : moment(span.startTime),
                        end_time       : moment(span.endTime),
                        status         : status2,
                        color          : color1 === 'rgb(250, 200, 200)' || color1 === 'rgba(250, 200, 200, 0.8)'  || color1 === 'rgba(250, 200, 200, 0.8)' || color1 === 'rgba(106, 224, 161 , 0.95)' ? 'black' : 'white',
                        bgColor        : color1,
                        selectedBgColor: color2,
                        selectedBorder : '2px',
                        selected       : false,
                        className      : (moment(span.startTime).day() === 6 || moment(span.endTime).day() === 0) ? 'item-weekend'   : '',
                        itemProps      : {
                            style  : {
                                fontSize: '5px',
                            },
                            purpose: span.purpose,
                            ...span.purpose === 'Order' || span.purpose === 'unassigned'?{
                                'data-tip' : 'tooltip',
                                'orderId'  : span.orderId,
                                'timespanId': span.id,
                                'toppingId': span.toppingId,
                            }: {},
                            ...['Cleaning','Maintenance','VIP'].includes(span.purpose)?{
                                'data-tip' : 'tooltip',
                                targetId: span.id,
                            }: {},
                        }
                    })
                })
            });
        });

        var items2 = _items.sort(function(a, b) { return b - a; })
        this.setState({
            newGroups: _groups,
            newItems : items2,
            loading  : true
        })
    }

    itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : item.selectedBgColor) : item.bgColor;
        const borderColor = 'white';
        return (
            <div
                {...getItemProps({
                    itemTouchSendsClick: true,
                    style: {
                        backgroundColor,
                        fontSize    : '9px',
                        padding     : "3px 0",
                        color       : item.color,
                        border      : 'none',
                        borderColor,
                        borderStyle: "solid",
                        borderWidth: 0,
                        borderRadius: 2,
                        borderLeftWidth: 0,
                        borderRightWidth: 0
                    },
                    onTouchEnd: () => {
                        var currentTime = new Date().getTime();
                        var tapLength = currentTime - this.state.lastTap;
                        clearTimeout(this.state.timeout);
                        if(tapLength < 500 && tapLength > 0){
                            console.log('dblclick')
                            console.debug(item.itemProps.purpose)
                            console.debug('trigger channel >>', window.parent?.postMessage);
                            let obj = {}
                            if(item.itemProps.purpose === 'Order' || item.itemProps.purpose === 'unassigned') {
                                obj = {
                                    orderId: item.itemProps.orderId,
                                    toppingId:  item.itemProps.toppingId,
                                    type: 'matrixOrderMatrix'
                                }
                            } else if (['Cleaning','Maintenance','VIP'].includes(item.itemProps.purpose)){
                                obj = {
                                    targetId: item.itemProps.targetId,
                                    type: 'matrixTimespan'
                                }
                            }
                            ;window.parent?.postMessage(obj, '*');
                            ;window.MATRIX_CHANNEL?.postMessage(JSON.stringify(obj), '*');
                        }else{
                            const timer = setTimeout(function(){
                                clearTimeout(timer);
                            }, 500);
                            this.setState({
                                timeout: timer
                            })
                        }
                        this.setState({
                            lastTap: currentTime
                        })
                    },
                    onDoubleClick: () => {
                        console.log('dblclick')
                        console.debug(item.itemProps.orderId)
                        console.debug('trigger channel >>', window.parent?.postMessage);
                        let obj = {}
                            if(item.itemProps.purpose === 'Order' || item.itemProps.purpose === 'unassigned') {
                                obj = {
                                    orderId: item.itemProps.orderId,
                                    toppingId:  item.itemProps.toppingId,
                                    type: 'matrixOrderMatrix'
                                }
                            } else if (['Cleaning','Maintenance','VIP'].includes(item.itemProps.purpose)){
                                obj = {
                                    targetId: item.itemProps.targetId,
                                    type: 'matrixTimespan'
                                }
                            }
                            ;window.parent?.postMessage(obj, '*');
                            ;window.MATRIX_CHANNEL?.postMessage(JSON.stringify(obj), '*');
                    },
                    onMouseDown: () => {
                        item.selectedBgColor = 'blue';
                    },
                    onMouseUp: () => {

                    }
                })}
            >
                {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
                <div
                    style={{
                        height      : itemContext.dimensions.height,
                        overflow    : 'hidden',
                        paddingLeft : 4,
                        textOverflow: 'ellipsis',
                        lineHeight  : '16px',
                        whiteSpace  : 'nowrap'
                    }}
                >
                    {itemContext.title}
                </div>
                {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
            </div>
        );
    };

    toggleGroup = (id) => {
        const { openGroups } = this.state;
        this.setState({
            openGroups: {
                ...openGroups,
                [id]: !openGroups[id]
            }
        },()=>{
            // console.log(this.state.openGroups)
        });
    };

    toggleStrikethrough = () => {
        this.setState(prevState => ({
            isCancelStrike: !prevState.isCancelStrike
        }));
    };

    togglePendingStrik = () => {
        this.setState(prevState => ({
            isPendingStrike: !prevState.isPendingStrike
        }));
    }

    toggleConfirmStrik = () => {
        this.setState(prevState => ({
            isConfirmStrike: !prevState.isConfirmStrike
        }));
    }

    togglePrecheckinStrik = () => {
        this.setState(prevState => ({
            isPrecheckinStrike: !prevState.isPrecheckinStrike
        }));
    }

    toggleCheckinStrik = () => {
        this.setState(prevState => ({
            isCheckinStrike: !prevState.isCheckinStrike
        }));
    }

    toggleCheckoutStrik = () => {
        this.setState(prevState => ({
            isCheckoutStrike: !prevState.isCheckoutStrike
        }));
    }

    toggleTimeblockStrik = () => {
        this.setState(prevState => ({
            isTimeblockStrike: !prevState.isTimeblockStrike
        }));
    }

    handleItemMove = (itemId, dragTime, newGroupOrder) => {
        const { newItems, newGroups } = this.state;
        // 被移动到的space
        const group = newGroups[newGroupOrder];
        // order不能被放置到root为true的space树根节点中，只能放到具体的space中
        if(!group.root){
            const currentItem = newItems.filter(item => item.id === itemId ? item : null);
            this.setState({
                newItems: newItems.map(item =>
                    item.id === itemId
                    ? Object.assign({}, item, {
                            group: group.id
                        })
                    : item
                )
            }, (async ()=>{
                const selectedItem = this.state.newItems.filter(item => item.id === itemId ? item : null);
                const selectedSpace = this.state.newGroups.filter(group => group.id === selectedItem[0].group ? group : null);
                console.log('被选中的节点', currentItem[0])
                console.log('被选中的space', selectedSpace[0])
                // 如果是在同一房间内拖动，不做处理
                if(currentItem[0].group === selectedSpace[0].id){
                    return;
                }
                const result = await confirm(`Confirm change?`, {});
                if(result){
                    const options = {
                        method: 'PATCH',
                        url: `${process.env.REACT_APP_PAYLOAD_URL}/api/v1/matrix/timespans`,
                        headers: {
                            'content-type' : 'application/json',
                            'Authorization': this.state.token,
                        },
                        data: JSON.stringify({
                            "orderId"   : selectedItem[0].itemProps.orderId,
                            "toppingId" : selectedItem[0].itemProps.toppingId,
                            "targetId"  : selectedSpace[0].itemProps.targetId,
                            "timespanId": selectedItem[0].itemProps.timespanId
                        })
                    };
                    // console.log(options)
                    await axios.request(options);
                    this.querySpaceOrder();
                    return;
                }else{
                    this.setState({
                        newItems: newItems.map(item =>
                            item.id === itemId
                            ? Object.assign({}, item, {
                                    group: currentItem[0].group
                                })
                            : item
                        )
                    })
                }
            }));
        }
        // console.log("Moved", itemId, dragTime, newGroupOrder);
    };

    // handleItemResize = (itemId, time, edge) => {
    //     const { newItems } = this.state;

    //     this.setState({
    //         newItems: newItems.map(item =>
    //             item.id === itemId
    //             ? Object.assign({}, item, {
    //                 start: edge === "left" ? time : item.start,
    //                 end: edge === "left" ? item.end : time
    //                 })
    //             : item
    //         )
    //     });
    //     console.log("Resized", itemId, time, edge);
    // };

    filterOrder = (statusItem) => {
        this.setState(prevState => {
            const statusFilter = prevState.statusFilter.includes(statusItem)
                ? prevState.statusFilter.filter(item => item !== statusItem)
                : [...prevState.statusFilter, statusItem];
            return { statusFilter };
        });
    };

    render() {
        const { t } = this.props;
        const { loading, newGroups, newItems, openGroups, statusFilter } = this.state;
        const filterNewItems = newItems.filter(item => statusFilter.includes(item.status));

        const newGroups2 = newGroups
            .filter(g => g.root || openGroups[parseInt(g.parent)])
            .map(group => {
                return Object.assign({}, group, {
                title: group.root ? (
                    <div onClick={() => this.toggleGroup(parseInt(group.id))} style={{ cursor: "pointer" }}>
                        <b>{openGroups[parseInt(group.id)] ? "[-]" : "[+]"} {group.title}</b>
                    </div>
                ) : (
                    <div style={{ paddingLeft: 20 }} onClick={()=>{
                    }}>{group.title}</div>
                )
                });
            });

        return (
            loading ?
            (<div onClick={()=>{  }}>
                <>
            <Timeline
                ref={r => { this.timeline = r; }}
                groups={newGroups2}
                items={filterNewItems}
                keys={{
                    groupIdKey        : "id",
                    groupTitleKey     : "title",
                    groupRightTitleKey: "rightTitle",
                    itemIdKey         : "id",
                    itemTitleKey      : "title",
                    itemDivTitleKey   : "div_title",
                    itemGroupKey      : "group",
                    itemTimeStartKey  : "start_time",
                    itemTimeEndKey    : "end_time",
                    groupLabelKey     : "title"
                }}
                sidebarWidth={140}
                itemsSorted
                itemTouchSendsClick={false}
                stackItems = {true}
                itemHeightRatio={0.75}
                showCursorLine
                canMove={true}
                canResize={false}
                lineHeight={30}
                minZoom={3 * 60 * 60 * 1000}
                maxZoom={1 * 60 * 86400 * 1000}
                timeSteps={{
                    minute: 15,
                    hour  : 1,
                    day   : 1,
                    month : 1,
                    year  : 1,
                }}
                defaultTimeStart={moment().startOf("day").add(-10, 'day')}
                defaultTimeEnd={moment().startOf("day").add(10, 'day')}
                itemRenderer={this.itemRenderer}
                onBoundsChange={(canvasTimeStart, canvasTimeEnd)=>{
                    console.log('canvasTimeStart', moment(canvasTimeStart).add(25, "days").format("YYYY-MM-DD"))
                    console.log('canvasTimeEnd', moment(canvasTimeEnd).add(-25, "days").format("YYYY-MM-DD"))
                    this.setState({
                        resources : [],
                        startTime : moment(canvasTimeStart).format("YYYY-MM-DD"),
                        endTime   : moment(canvasTimeEnd).format("YYYY-MM-DD")
                    },()=>{

                    });
                    this.querySpaceOrder();
                }}
                onItemSelect={(itemId, e, time)=>{ }}
                onItemMove={this.handleItemMove}
                // onItemResize={this.handleItemResize}
                // handleTimeChange = {(visibleTimeStart, visibleTimeEnd,updateScrollCanvas) => {
                //     updateScrollCanvas(moment(this.state.defaultTimeStart).valueOf(),
                //     moment(this.state.defaultTimeEnd).valueOf());
                // }}
            >
                <TimelineHeaders>
                    <SidebarHeader>
                        {({ getRootProps,showPeriod }) => {
                            return <div>
                                <div style={{ color: 'white' }}
                                        {...getRootProps()}><br/>&nbsp;<b>{t('space')}
                            </b></div>

                                <div style={{margin:'10px', display: 'flex', justifyContent:'space-between'}}>
                                    <button style={{margin:'5px', backgroundColor: '#555555', color: '#FFFFFF', border: 'none', cursor:'pointer'}} onClick={() => this.timeline.changeZoom(1.5)}>-</button>
                                    <div style={{alignSelf:'center'}}>{t('zoom')}</div>
                                    <button style={{margin:'5px', backgroundColor: '#555555', color: '#FFFFFF', border: 'none', cursor:'pointer'}} onClick={() => this.timeline.changeZoom(0.5)}>+</button>
                                </div>
                            </div>
                        }}
                    </SidebarHeader>

                    <DateHeader unit="primaryHeader" style={{ backgroundColor: '#212121' }} />
                    <CustomHeader height={22} headerData={{someData: 'data'}} unit="week">
                        {({
                              headerContext: { intervals },
                              getRootProps,
                              getIntervalProps,
                              showPeriod,
                              data,
                          }) => {
                            return (
                                <div {...getRootProps()}>
                                    {intervals.map(interval => {
                                        const intervalStyle = {
                                            textAlign: 'center',
                                            borderLeft: '1px solid black',
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }
                                        const isYearView = intervals.length > 100
                                        return (
                                            <div
                                                onClick={() => {
                                                    showPeriod(interval.startTime, interval.endTime)
                                                }}
                                                {...getIntervalProps({
                                                    interval,
                                                    style: intervalStyle
                                                })}
                                            >
                                                <span className="rct-dateHeader">
                                                    <div> {isYearView ?
                                                        <span style={{fontSize: '8px'}}>W{interval.startTime.format('W')}</span>
                                                        :
                                                        `Week ${interval.startTime.format('W')}`}</div>
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }}
                    </CustomHeader>

                    <DateHeader />
                </TimelineHeaders>
                <TodayMarker>
                    {({ styles, date }) => {
                        const customStyles = {
                            ...styles,
                            backgroundColor: 'red',
                            width: '3px'
                        }
                        return <div style={customStyles} onClick={()=>{}} />
                    }}
                </TodayMarker>
            </Timeline>
            <div id="legend">
                <table id="table">
                    <tbody>
                        <tr>
                            <td onClick={() => this.filterOrder('pending')}>
                                <svg width="12" height="12">
                                    <circle cx="6" cy="6" r="6" style={statusFilter.includes('pending') ? { fill: 'rgba(255, 81, 43 , 1)' } : { fill: 'rgba(255, 81, 43 , 0.2)' }} />
                                </svg>
                            </td>
                            <td style={{
                                textDecoration: this.state.isPendingStrike ? 'line-through' : 'none',
                                cursor: 'pointer'
                            }} onClick={() => {
                                this.filterOrder('pending');
                                this.togglePendingStrik();
                            }}>{t('pending')}</td>
                        </tr>
                        <tr>
                            <td onClick={() => this.filterOrder('confirmed')}>
                                <svg width="12" height="12">
                                    <circle cx="6" cy="6" r="6" style={statusFilter.includes('confirmed') ? { fill: 'rgba(103, 152, 114, 1)' } : { fill: 'rgba(103, 152, 114, 0.2)' }} />
                                </svg>
                            </td>
                            <td style={{
                                textDecoration: this.state.isConfirmStrike ? 'line-through' : 'none',
                                cursor: 'pointer'
                            }} onClick={() => {
                                this.filterOrder('confirmed');
                                this.toggleConfirmStrik();
                            }}>{t('confirmed')}</td>
                        </tr>
                        <tr>
                            <td onClick={() => this.filterOrder('precheckin')}>
                                <svg width="12" height="12">
                                    <circle cx="6" cy="6" r="6" style={statusFilter.includes('precheckin') ? { fill: 'rgba(106, 224, 161 , 1)' } : { fill: 'rgba(106, 224, 161 , 0.2)' }} />
                                </svg>
                            </td>
                            <td style={{
                                textDecoration: this.state.isPrecheckinStrike ? 'line-through' : 'none',
                                cursor: 'pointer'
                            }} onClick={() => {
                                this.filterOrder('precheckin');
                                this.togglePrecheckinStrik();
                            }}>{t('precheckin')}</td>
                        </tr>
                        <tr>
                            <td onClick={() => this.filterOrder('checkin')}>
                                <svg width="12" height="12">
                                    <circle cx="6" cy="6" r="6" style={statusFilter.includes('checkin') ? { fill: 'rgba(0, 122, 254 , 1)' } : { fill: 'rgba(0, 122, 254 , 0.2)' }} />
                                </svg>
                            </td>
                            <td style={{
                                textDecoration: this.state.isCheckinStrike ? 'line-through' : 'none',
                                cursor: 'pointer'
                            }} onClick={() => {
                                this.filterOrder('checkin');
                                this.toggleCheckinStrik();
                            }}>{t('checkin')}</td>
                        </tr>
                        <tr>
                            <td onClick={() => this.filterOrder('checkout')}>
                                <svg width="12" height="12">
                                    <circle cx="6" cy="6" r="6" style={statusFilter.includes('checkout') ? { fill: 'rgba(0, 0, 0 , 1)' } : { fill: 'rgba(0, 0, 0 , 0.2)' }} />
                                </svg>
                            </td>
                            <td style={{
                                textDecoration: this.state.isCheckoutStrike ? 'line-through' : 'none',
                                cursor: 'pointer'
                            }} onClick={() => {
                                this.filterOrder('checkout');
                                this.toggleCheckoutStrik();
                            }}>{t('checkout')}</td>
                        </tr>
                        <tr>
                            <td onClick={() => this.filterOrder('timeblock')}>
                                <svg width="12" height="12">
                                    <circle cx="6" cy="6" r="6" style={ statusFilter.includes('timeblock') ? { fill: 'rgba(165, 30, 32 , 0.5)' } : { fill: 'rgba(165, 30, 32 , 0.2)' }} />
                                </svg>
                            </td>
                            <td style={{
                                textDecoration: this.state.isTimeblockStrike ? 'line-through' : 'none',
                                cursor: 'pointer'
                            }} onClick={() => {
                                this.filterOrder('timeblock');
                                this.toggleTimeblockStrik();
                            }}>{t('timeblock')}</td>
                        </tr>
                        <tr>
                            <td onClick={() => this.filterOrder('cancelled')}>
                                <svg width="12" height="12">
                                    <circle cx="6" cy="6" r="6" style={statusFilter.includes('cancelled') ? { fill: 'rgba(255, 215, 64 , 0.8)' } : { fill: 'rgba(255, 215, 64 , 0.2)' }} />
                                </svg>
                            </td>
                            <td style={{
                                textDecoration: this.state.isCancelStrike ? 'line-through' : 'none',
                                cursor: 'pointer'
                            }} onClick={() => {
                                this.filterOrder('cancelled');
                                this.toggleStrikethrough();
                            }}>{t('cancelled')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
                </>
            </div>) : <div id='loading' className='loading'><img src={loading_img} style={{ width: '48px', height: '48px' }} alt=""/></div>
        );
    }
}
export default withTranslation()(SpaceOrderCalendar);
